.chart-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  padding: 0 15px;
  box-sizing: border-box;
}

.file-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  width: 100%;
  max-width: 400px;
  margin: 0 auto 20px;
}

.file-picker.dragging {
  background-color: #f0f0f0;
  border-color: #999;
}

.file-picker .logo {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.file-picker span {
  margin-bottom: 10px;
  text-align: center;
  font-size: 14px;
}

.file-picker input[type="file"] {
  margin-bottom: 10px;
}

.file-picker p {
  margin: 0;
  color: #666;
}

.chart-container .recharts-wrapper {
  margin-top: 20px;
}

.chart-container .recharts-legend-wrapper {
  bottom: -40px !important;
}

.chart-container .recharts-surface {
  overflow: visible;
}

.disclaimer {
  margin-top: 0px;
  font-size: 10px;
}

.test-info {
  margin-top: 50px;
  font-size: 14px;
}

.test-info .test-type {
  font-weight: bold;
}

.disclaimer-bold {
  font-weight: bold;
}

@media (max-width: 600px) {
  .chart-container {
    margin: 10px auto;
  }

  .file-picker {
    padding: 10px;
  }

  .file-picker span {
    font-size: 14px;
  }

  .disclaimer {
    font-size: 8px;
  }

  .test-info {
    margin-top: 30px;
    font-size: 14px;
  }
}
